import React, { useContext, useEffect } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/baby/eczema/eczema-relief-product-front.webp";
import backImage from "images/baby/eczema/eczema-relief-product-back.webp";
import frontZoomImage from "images/baby/eczema/eczema-relief-product-front-zoom.webp";
import backZoomImage from "images/baby/eczema/eczema-relief-product-back-zoom.webp";

import img4 from "images/callouts/cout-childrens-itch-footer.webp";
import img5 from "images/callouts/cout-baby-eczema-footer.webp";
import img6 from "images/callouts/cout-eczema-card.webp";

import imgChart1 from "images/baby/eczema/chart-eucerin-eczema-flare.webp";
import imgChart2 from "images/baby/eczema/chart-eucerin-eczema-reduction.webp";

import "./eucerinbabyeczemareliefcream.scss";

function EucerinBabyEczemaPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-baby-eczema-relief-cream page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Baby Eczema Relief Cream"
        pageDescription="Learn about Eucerin Baby Eczema Relief Cream"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#A70531"
              categoryName="ECZEMA"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Baby Eczema Relief Cream"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold red-text sub-title">
                  A clinically proven, steroid-free formula for eczema-prone
                  skin
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Baby Eczema Relief Cream
                  </span>{" "}
                  helps relieve itching, minor irritation, and dryness due to
                  eczema. It’s part of a complete routine to relieve the
                  symptoms of eczema in pediatric patients as young as 3 months.
                </p>
                <ul className="red-bullets">
                  <li>
                    <span>
                      Appropriate for use on infants as young as 3 months
                    </span>
                  </li>
                  <li>
                    <span className="font-semi-bold">
                      Clinically proven to reduce the incidence of flares and
                      increase time to flare recurrence with daily use
                      <sup>1</sup>
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Fast-absorbing formula
                      </span>{" "}
                      hydrates and helps strengthen the skin’s barrier
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold red-text sub-title">
                  Active ingredient
                </p>
                <p className="font-semi-bold">
                  1% colloidal oatmeal (skin protectant)
                </p>
                <ul className="red-bullets">
                  <li>
                    <span>
                      Provides a protective layer to soothe; reduces itch
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold red-text sub-title">
                  Key ingredients
                </p>
                <p className="font-semi-bold">Ceramide NP</p>
                <ul className="red-bullets">
                  <li>
                    <span>Helps support skin’s moisture barrier</span>
                  </li>
                </ul>
                <p className="font-semi-bold">Licochalcone A</p>
                <ul className="red-bullets">
                  <li>
                    <span>Helps soothe red, irritated skin</span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold red-text sub-title">
                    Formulated for eczema-prone skin
                  </p>
                  <ul className="checkmarks red">
                    <li>
                      <span>STEROID-FREE </span>
                    </li>
                    <li>
                      <span>Fragrance-free </span>
                    </li>
                    <li>
                      <span>dye-free </span>
                    </li>
                    <li>
                      <span>paraben-free</span>
                    </li>
                    <li>
                      <span>non-comedogenic</span>
                    </li>
                  </ul>
                </div>
                <p className="references last">
                  <span className="font-bold">Reference: 1.</span> Weber TM,
                  Samarin F, Babcock MJ, Filbry A, Rippke F. Steroid-free
                  over-the-counter eczema skin care formulations reduce{" "}
                  <br className="show-desktop" /> risk of flare, prolong time to
                  flare, and reduce eczema symptoms in pediatric subjects with
                  atopic dermatitis.{" "}
                  <span className="font-italic">J Drugs Dermatol</span>.{" "}
                  <br className="show-desktop" /> 2015;14(5):478-485.
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="FLARE REDUCTION"
              productThemeColor="eczema-red"
              id="flarereduction"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={imgChart1}
                    className="accordion-chart"
                    alt="Graph with number of children flare-free in 6 months with use of Eucerin Eczema Relief Cream"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> 180-day
                    assessment of children aged 3 months to 12 years with atopic
                    dermatitis who, after a <nobr>2-week</nobr> wash-out period,
                    applied Eucerin Eczema Relief Cream daily in addition to a
                    cleanser (n=19) vs a control group using only a cleanser
                    (n=23).
                  </p>
                  <p className="references">
                    The differences between groups in time to flare and the
                    number of subjects who experienced flare were statistically
                    significant (<span className="font-italic">p</span>
                    &lt;0.05).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Weber TM,
                    Samarin F, Babcock MJ, Filbry A, Rippke F. Steroid-free
                    over-the-counter eczema skin care formulations reduce risk
                    of flare, prolong time to flare, and reduce eczema symptoms
                    in pediatric subjects with atopic dermatitis.{" "}
                    <span className="font-italic">J Drugs Dermatol</span>.
                    2015;14(5):478-485.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    4 out of 5 children remained <nobr>flare-free</nobr> for{" "}
                    <nobr>
                      6 months
                      <sup>1</sup>
                    </nobr>
                  </div>
                </Col>
              </Row>
            </AccordionContent>

            <AccordionContent
              accordionHeading="IRRITATION REDUCTION"
              productThemeColor="eczema-red"
              id="irritationreduction"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={imgChart2}
                    className="accordion-chart"
                    alt="Graph with reduced skin irritation in children"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> For 14
                    days, Eucerin Eczema Relief Cream was applied twice daily to
                    the lower legs of children (aged 3 months to 12 years) with
                    a history of atopic dermatitis (N=64).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 2.</span> Weber TM,
                    Herndon JH, Ewer M, et al. Efficacy and tolerability of
                    steroid-free, over-the-counter treatment formulations in
                    infants and children with atopic dermatitis.{" "}
                    <span className="font-italic">J Dermatol Nurses Assoc</span>
                    . 2015;7(1):17-24.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    <p className="spacer">
                      82% reduction in roughness at <nobr>Day 7</nobr>
                    </p>
                    <p className="spacer">
                      95% reduction in roughness at <nobr>Day 14</nobr>
                    </p>
                    <p className="spacer">
                      64% reduction in
                      <br className="show-desktop" /> itching at{" "}
                      <nobr>Day 7</nobr>
                    </p>
                    <p className="spacer">
                      85% reduction in
                      <br className="show-desktop" />
                      itching at <nobr>Day 14</nobr>
                    </p>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg section-support-resource red">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                <TypeC
                  buttonText="Download here"
                  buttonUrl={getUrlFromSlug(
                    "eucerin-eczema-relief-regimen",
                    state?.sanityData?.allResources
                  )}
                  className="center-v"
                  isExternalLink={true}
                  targetOpen="_blank"
                >
                  <p className="reg-text-big">
                    Support your recommendation <br className="show-desktop" />
                    with this resource
                  </p>
                  <p className="reg-text">
                    Share with parents the clinical data demonstrating
                    significantly reduced
                    <br className="show-desktop" /> incidence of flares and
                    increased intervals between flares.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                <img
                  src={img6}
                  alt="Resource to protect eczema-prone skin"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img4}
                  imgAlt="ITCH RELIEF FOR CHILDREN"
                  caption="Help soothe and relieve itch <br class='show-desktop' /> for up to 12 hours "
                  buttonText="ITCH RELIEF FOR CHILDREN"
                  buttonUrl="/baby/generalcare/aquaphorchildrensitchreliefointment"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img5}
                  imgAlt="EUCERIN BABY ECZEMA RELIEF"
                  caption="Recommend a proactive regimen <br class='show-desktop' /> for babies’ eczema-prone skin"
                  buttonText="EUCERIN BABY ECZEMA RELIEF"
                  buttonUrl="/baby/eczema"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default EucerinBabyEczemaPage;
